import React from 'react'
import ApproachHeader from 'components/approach/approachHeader'
import MainNav from 'components/functional/mainNav'
import Layout from 'components/layout'
import ApproachBody from 'components/approach/approachBody'

const metaData = {
  title: 'Approach | D E W Y N T E R S',
  description: `We’re Dewynters, the leading integrated live entertainment advertising agency. Based in London, we create best in class campaigns for theatre, immersive, festivals and exhibitions. | Approach`
}

const Approach = () => (
  <Layout title={metaData.title} description={metaData.description}>  
    <MainNav alt />
    <ApproachHeader>
      <h1>
        <span>
          We love it when you’re the centre of attention
          <span className="dot">.</span>
        </span>
      </h1>
    </ApproachHeader>
    <ApproachBody />
  </Layout>
)

export default Approach
